.glitch {
  animation: glitch-skew 4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 4s infinite;
  font-family: orbitron, sans-serif;
  font-size: 9rem;
  font-weight: 900;
  line-height: 1;
  position: relative;
  text-align: center;
  transform-origin: center top;

  &__main {
    color: #fff;
  }

  &__color {
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    transform-origin: center center;
    width: 100%;
    z-index: -1;

    &--red {
      animation: glitch 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
      color: red;
    }

    &--blue {
      animation: glitch 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
        reverse;
      color: blue;
    }

    &--green {
      animation: glitch 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 100ms
        infinite;
      color: #00ff0b;
    }
  }

  &__line {
    animation: glitch-line 2s linear infinite;
    background: #000;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    &--first {
      animation: glitch-line 2s linear infinite;
    }

    &--second {
      animation: glitch-line 1s linear 1s infinite;
    }
  }
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-8px, 8px);
  }
  40% {
    transform: translate(-8px, -8px);
  }
  60% {
    transform: translate(8px, 8px);
  }
  80% {
    transform: translate(8px, -8px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes glitch-skew {
  0% {
    transform: skew(0deg, 0deg);
  }
  48% {
    transform: skew(0deg, 0deg);
    filter: blur(0);
  }
  50% {
    transform: skew(-20deg, 0deg);
    filter: blur(4px);
  }
  52% {
    transform: skew(20deg, 0deg);
  }
  54% {
    transform: skew(0deg, 0deg);
    filter: blur(0);
  }
  100% {
    transform: skew(0deg, 0deg);
  }
}

@keyframes glitch-line {
  0% {
    top: 0;
  }

  100% {
    top: 100%;
  }
}

.stars {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &__star {
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #fff;
    height: 1px;
    position: absolute;
    width: 1px;
    z-index: 100;

    @for $i from 1 through 100 {
      &:nth-child(#{$i}) {
        top: random(100) * 1%;
        left: random(100) * 1%;
        box-shadow: 0 0 4px random(3) * 1px #fff;
      }
    }
  }
}
